<template>
    <Content :search="false">
        <template v-slot:beforeSearch>
            <div class="flex items-center text-lg font-semibold dark:text-gray-300">
                <Button icon="arrow-left" @click="goBack" />
                <h2>Inventerizācija</h2>
            </div>
        </template>
        
        <template v-slot:content>
            <template v-if="stockCheck">
                <div class="sm:rounded-md mb-3">
                    <ul role="list">
                        <StockCheckDetails :item="stockCheck" />

                        <template v-if="stockCheck.errors && stockCheck.errors.length > 0">
                            <StockCheckErrors :errors="stockCheck.errors" />
                        </template>

                        <template v-if="stockCheck.items">
                            <StockCheckItems :stockCheck="stockCheck" />
                        </template>
                    </ul>
                </div>
            </template>
        </template>
    </Content>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

const StockCheckDetails = defineAsyncComponent(() =>
  import('@/components/Stock/StockChecks/StockCheckDetails/StockCheckDetails'))
const StockCheckErrors = defineAsyncComponent(() =>
  import('@/components/Stock/StockChecks/StockCheckErrors/StockCheckErrors'))
const StockCheckItems = defineAsyncComponent(() =>
  import('@/components/Stock/StockChecks/StockCheckItems/StockCheckItems'))

export default {
  name: "StockCheck",
  components: {
      StockCheckDetails,
      StockCheckErrors,
      StockCheckItems,
  },
  data: () => ({
  }),
  created() {
    this.$store.dispatch('clearSingleSupplier')
    this.$store.dispatch('getStockCheck', this.$route.params.id)
  },
  computed: {
    ...mapGetters({
      stockCheck: 'stockCheck',
      formsForDisplay: 'formsForDisplay'
    }),
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
  }
}
</script>

<style>

</style>