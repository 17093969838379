<template>
  <Content :search="false" v-if="technicalDocument">
    <template v-slot:beforeSearch>
      <div class="flex items-center text-lg font-semibold dark:text-gray-300">
        <Button icon="arrow-left" @click="goBack" />
        <h2>Dokuments {{technicalDocument.uuid}}</h2>
      </div>
    </template>
    
    <template v-slot:content>
      <div>
        <div class="sm:rounded-md mb-3">
          <ul role="list" class="">
            <template v-if="technicalDocument.documentable_info.type === 'GoodsRemoval'">
              <TehnicalDocumentDetails :document="technicalDocument" :status="status" />

              <template v-for="(item, index) in technicalDocument.documentable.removal_items" :key="index">
                <SingleTehnicalDocumentDetails :item="item" />
              </template>

              <template v-if="status">
                <div class="flex flex-wrap gap-3 justify-end">
                  <template v-if="status.id === 1">
                    <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-blue-600 bg-blue-200 hover:bg-blue-300 hover:text-blue-700 focus:outline-none focus:border-none transition duration-500 ease-in-out" @click="updateGoodsRemoval(2)">
                      Nodot piegādei
                    </button>
                  </template>

                  <template v-if="status.id === 2">
                    <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-green-600 bg-green-200 hover:bg-green-300 hover:text-green-700 focus:outline-none focus:border-none transition duration-500 ease-in-out" @click="updateGoodsRemoval(3)">
                      Pieņemt
                    </button>
                  </template>
                </div>
              </template>
            </template>

            <template v-if="technicalDocument.documentable_info.type === 'StockLevelCorrection'">

              <TehnicalDocumentDetails :document="technicalDocument" />

              <SingleStockCorrectionItemDetails v-for="(item, index) in technicalDocument.documentable.correction_items" :key="index" :item="item"/>
            </template>

            <template v-if="technicalDocument.documentable_info.type === 'GoodsWriteOff'">

              <TehnicalDocumentDetails :document="technicalDocument" />

              <template v-if="!technicalDocument.documentable.is_accepted">
                <button
                  type="button"
                  class=" flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out ml-auto mb-3
                  "
                  @click="updateWriteOff"
                >
                  Akceptēt
                </button>
              </template>

              <template v-if="technicalDocument.documentable.write_off_items && technicalDocument.documentable.write_off_items.length">
                <StockWriteOffItems :items="technicalDocument.documentable.write_off_items" :warehouse="technicalDocument.documentable.warehouse" :technicalDocument="technicalDocument" />
              </template>
              
            </template>

          </ul>
        </div>
      </div>
    </template>
  </Content>
</template>

<script>
import { mapGetters } from 'vuex'

import { defineAsyncComponent } from 'vue'

const TehnicalDocumentDetails = defineAsyncComponent(() =>
  import('@/components/Stock/TehnicalDocuments/TehnicalDocumentDetails/TehnicalDocumentDetails'))
const SingleTehnicalDocumentDetails = defineAsyncComponent(() =>
  import('@/components/Stock/TehnicalDocuments/TehnicalDocumentDetails/SingleTehnicalDocumentDetails'))
const SingleStockCorrectionItemDetails = defineAsyncComponent(() =>
  import('@/components/Stock/TehnicalDocuments/TehnicalDocumentDetails/SingleStockCorrectionItemDetails'))
const StockWriteOffItems = defineAsyncComponent(() =>
  import('@/components/Stock/TehnicalDocuments/WriteOff/StockWriteOffItems'))

export default {
  name: "TechnicalDocument",
  components: {
    TehnicalDocumentDetails,
    SingleTehnicalDocumentDetails,
    SingleStockCorrectionItemDetails,
    StockWriteOffItems,
  },
  data() {
    return {
      status: null,
      statuses: [
        {id: 1, name:'Jauns'},
        {id: 2, name:'Piegādāts'},
        {id: 3, name:'Pabeigts'},
      ]
    }
  },
  created() {
    this.$store.dispatch('getSingleTechnicalDocument', this.$route.params.documentId)
  },
  computed: {
    ...mapGetters({
      technicalDocument: 'technicalDocument',
      isLoading: 'loading'
    })
  },
  watch: {
    technicalDocument() {
      this.setStatuss()
    }
  },
  methods: {
    setStatuss() {
      if(this.technicalDocument.documentable_info.type !== 'GoodsRemoval') {
        return
      }
      this.status = this.statuses.find(status => {
        return status.id === this.technicalDocument.documentable.status_id
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    updateGoodsRemoval(statusId) {
      this.$store.dispatch('updateGoodsRemoval', {
        goodsRemovalId: this.technicalDocument.documentable.id,
        data: {
          status_id: statusId
        },
        documentId: this.technicalDocument.id
      })
    },
    updateWriteOff() {
      this.$store.dispatch('updateWriteOff', {
        id: this.technicalDocument.id,
        document_id: this.technicalDocument.documentable.id,
        data: {
          is_accepted: true
        },
      })
    }
  }
}
</script>

<style>

</style>