<template>
    <Content>
        <template v-slot:topbar>
            <div class="flex h-full items-center">
                <router-link to="/stock/stock_checks/create" class="rounded-full w-12 h-12 bg-gray-200 dark:bg-main4 flex     justify-center items-center leading-none hover:bg-gray-300 transition duration-300 ease-in-out dark:text-gray-100">
                    <PlusIcon class="w-7 h-7" />
                </router-link>
            </div>
        </template>

        <template v-slot:content>
            <template v-if="stockChecks && stockChecks.data.length > 0">
                <div class="sm:rounded-md mb-3">
                    <ul role="list" class="">
                    <template v-for="item in stockChecks.data" :key="item.id">
                        <StockChecksListItem :item="item" />
                    </template>
                    </ul>
                </div>

                <Pagination :meta="stockChecks.meta" :onPageChange="onPageChange" />
            </template>

            <template v-else>
                <p class="py-6 text-center dark:text-gray-300">
                    Diemžēl nekas netika atrasts
                </p>
            </template>
        </template>
    </Content>
</template>

<script>
import { debounce } from 'vue-debounce'
import {mapGetters} from "vuex";
import Search from "@/services/Helpers/search";

import { defineAsyncComponent } from 'vue'

const StockChecksListItem = defineAsyncComponent(() =>
  import('@/components/Stock/StockChecks/StockChecksListItem'))
const Pagination = defineAsyncComponent(() =>
  import('@/components/Components/Pagination'))

import {
  PlusIcon,
} from "@heroicons/vue/solid";

export default {
  name: "StockChecks",
  components: {
      StockChecksListItem,
      Pagination,
      PlusIcon,
  },
  mounted() {
    this.$store.dispatch('removeAllFormsForDisplay')
    this.$store.dispatch('resetSearch')
    const s = Search.getSearchQuery();
    if (s) {
      this.$store.dispatch('changeSearch', s)
      this.searchStockChecks()
    } else {
      this.$store.dispatch('getStockChecks', Search.getCurrentPage())
    }
  },
  created() {
    this.$store.dispatch("setDataLoading", true)
  },
  computed: {
    ...mapGetters({
      stockChecks: 'stockChecks',
      search: 'search',
    })
  },
  watch: {
    search: function () {
      this.searchStockChecks()
    }
  },
  methods: {
    searchStockChecks: debounce(function () {
      this.$store.dispatch('changeSearch', this.search)
      this.$store.dispatch('getStockChecks', Search.getCurrentPage())
    }, 500),
    onPageChange(page) {
      this.$store.dispatch('getStockChecks', page);
      Search.changePage(this.stockChecks.meta.current_page)
    },
    showForm(formName) {
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName) {
      return this.formsForDisplay.includes(formName)
    },
  }
}
</script>

<style>

</style>