<template>
  <Content :filter="true" :showFilterDefault="true">
    <template v-slot:topbar>
      <div class="flex h-full items-center">
        <router-link to="/stock/tehnical_documents/create" class="rounded-full w-12 h-12 bg-gray-200 dark:bg-main4 flex justify-center items-center leading-none hover:bg-gray-300 transition duration-300 ease-in-out dark:text-gray-100">
          <PlusIcon class="w-7 h-7" />
        </router-link>
      </div>
    </template>

    <template v-slot:filter>

      <div class="flex h-full items-center">
        <Radio v-model="filter.selectedType" :items="filterTypes" @change="getItems" />
      </div>

    </template>

    <template v-slot:content>
        <template v-if="technicalDocuments && technicalDocuments.data && technicalDocuments.data.length > 0">
          <div class="sm:rounded-md mb-3">
            <ul role="list" class="">
              <template v-for="item in technicalDocuments.data" :key="item.id">
                <TehnicalDocumentsListItem :item="item" :selectedTypeId="parseInt(filter.selectedType)" />
              </template>
            </ul>
          </div>

          <Pagination :meta="technicalDocuments.meta" :onPageChange="onPageChange" />
        </template>

        <template v-else>
            <p class="py-6 text-center dark:text-gray-300">
              Diemžēl nekas netika atrasts
            </p>
        </template>
    </template>
  </Content>
</template>

<script>
import { debounce } from 'vue-debounce'
import {mapGetters} from "vuex";
import Search from "@/services/Helpers/search";

import { defineAsyncComponent } from 'vue'

const TehnicalDocumentsListItem = defineAsyncComponent(() =>
  import('@/components/Stock/TehnicalDocuments/TehnicalDocumentsListItem'))
const Pagination = defineAsyncComponent(() =>
  import('@/components/Components/Pagination'))

import Radio from "@/components/Components/Radio"
import {
  PlusIcon,
} from "@heroicons/vue/solid";

export default {
  name: "TehnicalDocuments",
  components: {
    Pagination,
    TehnicalDocumentsListItem,
    Radio,
    PlusIcon,
  },
  data: () => ({
    filterTypes: [
      {
        name: "Preču pārvietošana",
        type: "goodsRemovals",
        value: 5
      },
      {
        name: "Atlikumu koriģēšana",
        type: "stockCorrections",
        value: 4
      },
      {
        name: "Preču norakstīšana",
        type: "goodsWriteOff",
        value: 7
      },
    ],
    filter: {
      selectedType: 5,
    },
    queryParams: null,
  }),
  created() {
    this.$store.dispatch("setDataLoading", true)

        // Get query params from URL
        this.queryParams = this.$route.query

        // If there are no URL params set default params
        if(Object.keys(this.queryParams).length === 0) {
            this.$router.replace({
                query: {
                    type_id: 5,
                    page: Search.getCurrentPage(),
                    q: this.search
                }
            })
        }

        // Update component data from query prams
        this.setFiltersFromParams()
        this.searchTehnicalDocuments()
  },
  mounted() {
    this.$store.dispatch('removeAllFormsForDisplay')
    this.$store.dispatch('resetSearch')
  },
  computed: {
    ...mapGetters({
      technicalDocuments: 'technicalDocuments',
      formsForDisplay: 'formsForDisplay',
      editableItem: 'editableItem',
      isLoading: "loading",
      search: "search",
    })
  },
  watch: {
    search: function () {
      this.searchTehnicalDocuments()
    },
  },
  methods: {
    getItems() {
      this.$store.dispatch("setDataLoading", true)
          this.$router.replace({
            query: {
                type_id: this.filter.selectedType ? this.filter.selectedType : 5,
                page: 1,
                q: this.queryParams.q
            }
        })

        this.queryParams = this.$route.query
        this.searchTehnicalDocuments()
    },
    setFiltersFromParams(){
        this.filter.selectedType = this.queryParams.type_id ? this.queryParams.type_id : 5;
    },

    searchTehnicalDocuments: debounce(function () {
      this.$store.dispatch('filterTehnicalDocuments', this.queryParams)
    }, 500),
    onPageChange(page) {
      this.searchTehnicalDocuments()
    },

    addTehnicalDocuments() {
      this.$router.push({ name: 'tehnical_documents.create'})
    },
    showTechnicalDocument(documentId) {
      this.$router.push({ path: '/stock/tehnical_documents/' + documentId})
    },
  }
}
</script>

<style>

</style>