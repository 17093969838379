<template>
  <Content :search="false">
    <template v-slot:beforeSearch>
      <div class="flex items-center text-lg font-semibold dark:text-gray-300">
        <Button icon="arrow-left" @click="goBack" />
        <h2>Jauns tehniskais dokuments</h2>
      </div>
    </template>

    <template v-slot:content>
      <div class="space-y-6 w-full bg-white dark:bg-gray-750 p-4 rounded-lg mb-3">

        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div class="col-span-1">
            <Select v-model="select" :items="selectOptions" placeholder="Dokuments" />
          </div>
        </div>
      </div>

      <template v-if="select && select.id === 1">
        <CreateStockBalance />
      </template>

      <template v-if="select && select.id === 2">
        <CreateStockMovement />
      </template>

      <template v-if="select && select.id === 3">
        <CreateStockWriteOff />
      </template>
    </template>
  </Content>
</template>

<script>
import { defineAsyncComponent } from 'vue'
const Select = defineAsyncComponent(() =>
  import('@/components/Components/Select'))
// const CreateStockForm = defineAsyncComponent(() =>
//   import('@/components/Stock/TehnicalDocuments/TehnicalDocumentsForms/CreateStockForm'))
const CreateStockBalance = defineAsyncComponent(() =>
  import('@/components/Stock/TehnicalDocuments/TehnicalDocumentsForms/CreateStockBalance'))
const CreateStockMovement = defineAsyncComponent(() =>
  import('@/components/Stock/TehnicalDocuments/TehnicalDocumentsForms/CreateStockMovement'))
const CreateStockWriteOff = defineAsyncComponent(() =>
  import('@/components/Stock/TehnicalDocuments/TehnicalDocumentsForms/CreateStockWriteOff'))

export default {
  name: "CreateTehnicalDocuments",
  components: {
    Select,
    CreateStockBalance,
    CreateStockMovement,
    CreateStockWriteOff,
  },
  data: () => ({
    select: null,
    selectOptions: [
      { id: 1, name: "Atlikumu koriģēšana" },
      { id: 2, name: "Preču pārvietošana" },
      { id: 3, name: "Preču norakstīšana" },
    ],
  }),
  methods: {
    goBack() {
      if (confirm("Vai esi drošs? Visi ievadītie lauki tiks pazaudēti")) {
        this.$router.go(-1);
      }
    },
  },
}
</script>