<template>
    <Content :search="false">
        <template v-slot:beforeSearch>
            <div class="flex items-center text-lg font-semibold dark:text-gray-300">
                <Button icon="arrow-left" @click="goBack" />

                <h2>Jauns inventerizācijas dokuments</h2>
            </div>
        </template>

        <template v-slot:content>
            <form v-if="stockCheckInputData" class="space-y-6 w-full bg-white dark:bg-gray-750 p-4 rounded-lg divide-y divide-gray-200 dark:divide-gray-700">

                <div class="grid grid-cols-12 gap-6">
                    <template v-if="types">
                        <div class="col-span-12">
                            <Radio label="Tips" name="type" v-model="form.type" :items="types" :errors="errors.type" />
                        </div>
                    </template>

                    <div class="col-span-12 sm:col-span-6">
                        <Select v-model="form.warehouse" :errors="errors.warehouse" :items="stockCheckInputData.warehouses" placeholder="Noliktava" />
                    </div>

                    <div class="col-span-12 sm:col-span-6">
                        <Input type="date" placeholder="Datums" v-model="form.date" :errors="errors.date"/>
                    </div>

                    <div class="col-span-12">
                        <FileInput placeholder="Pievienot failu" @files="getFiles" :errors="errors.excel" :multiple="false"/>
                    </div>

                </div>

                <div class="pt-5">
                    <div class="flex justify-end">
                        <button @click="goBack" type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2">
                            Atcelt
                        </button>

                        <template v-if="!loading">
                            <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
                                Pievienot
                            </button>
                        </template>
                        <template v-else>
                            <Loading />
                        </template>
                    </div>
                </div>
            </form>
        </template>
    </Content>
</template>

<script>
import {mapGetters} from "vuex";
import Radio from "@/components/Components/Radio";
import Select from "@/components/Components/Select";
import Input from "@/components/Components/Input";
import FileInput from "@/components/Components/FileInput";
import Loading from "@/components/Components/Loading";
import Validation from "@/modules/Validation";

export default {
    name: "CreateStockCheck",
    components: {
        Radio,
        Select,
        Input,
        FileInput,
        Loading,
    },
    data: () => ({
        types: null,
        form: {
            type: '1',
            date: new Date().toISOString().substr(0, 10),
            warehouse: null,
            excel: null,
        },
    }),
    computed: {
        ...mapGetters({
            stockCheckInputData: "stockCheckInputData",
            loading: "loading",
            errors: "errors",
        }),
        formValidation() {
            return {
                type: {
                    rules: ['required']
                },
                warehouse: {
                    rules: ['required']
                },
                date: {
                    rules: ['required']
                },
                excel: {
                    rules: ['required_array']
                },
            }
        }
    },
    created() {
        this.$store.dispatch("getStockChecksInputData").then(() => {
            this.types = Object.keys(this.stockCheckInputData.types).map(key => {
                return {
                    value: key,
                    name: this.stockCheckInputData.types[key],
                }
            })
        });
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        getFiles(files) {
            this.form.excel = files
        },
        submit() {
            let formData = new FormData();
            if (this.form.excel && this.form.excel.length > 0) {
                for (var i = 0; i < this.form.excel.length; i++) {
                    let file = this.form.excel[i];
                    formData.append("excel", file);
                }
            }

            this.$Progress.start();
            if (Validation(this.formValidation, this.form)) {
                this.$store.dispatch("setLoading");

                formData.append("warehouse_id", this.form.warehouse.id)
                formData.append("type_id", this.form.type)
                formData.append("date", this.form.date)

                this.$store.dispatch("createNewStockCheck", formData);
            } else {
                this.$Progress.fail();
            }
        }
    }
}
</script>