<template>
  <Content :filter="true">
    <template v-slot:topbar>
      <div class="flex h-full items-center">
        <router-link to="/stock/inventory_items/create" class="rounded-full w-12 h-12 bg-gray-200 dark:bg-main4 flex justify-center items-center leading-none hover:bg-gray-300 transition duration-300 ease-in-out dark:text-gray-100">
          <PlusIcon class="w-7 h-7" />
        </router-link>
      </div>
    </template>

    <template v-slot:filter>

      <div class="w-48 mr-3">
        <Select v-model="filter.selectedMainBranch" :items="mainCustomerBranches" placeholder="Filiāle" @click="getItems" :clearAble="true" :showPlaceholder="false" />
      </div>

      <div class="flex h-full items-center">
        <Radio name="selectedFilter" v-model="filter.selectedFilter" :items="filters" @change="getItems" />
      </div>

    </template>

    <template v-slot:content>
        <template v-if="inventoryItems && inventoryItems.data.length > 0">
          <div class="sm:rounded-md mb-3">
            <ul role="list" class="">
              <template v-for="item in inventoryItems.data" :key="item.id">
                <InventoryItemsListItem :item="item" :inventoryItems="inventoryItems" :selectedFilter="filter.selectedFilter" :selectedMainBranch="filter.selectedMainBranch" />
              </template>
            </ul>
          </div>

          <Pagination :meta="inventoryItems.meta" :onPageChange="onPageChange" />
        </template>

        <template v-else>
            <p class="py-6 text-center dark:text-gray-300">
              Diemžēl nekas netika atrasts
            </p>
        </template>
    </template>
  </Content>
</template>

<script>
import { debounce } from 'vue-debounce'
import {mapGetters} from "vuex";
import Search from "@/services/Helpers/search";

import { defineAsyncComponent } from 'vue'

const InventoryItemsListItem = defineAsyncComponent(() =>
  import('@/components/Stock/InventoryItems/InventoryItemsListItem'))
const Pagination = defineAsyncComponent(() =>
  import('@/components/Components/Pagination'))

import Select from "@/components/Components/Select"
import Radio from "@/components/Components/Radio"
import {
  PlusIcon,
} from "@heroicons/vue/solid";

export default {
  name: "InventoryItems",
  components: {
    Pagination,
    InventoryItemsListItem,
    Select,
    Radio,
    PlusIcon,
  },
  data: () => ({
    filter: {
      selectedFilter: 'all',
      selectedMainBranch: null,
    },
    filters: [
      { value: 'all',  name: 'visi'},
      { value: 'rented',  name: 'iznomāti'},
      { value: 'available',  name: 'mājās'},
    ],
    mainCustomerBranches: [
      { id: 1,  name: 'Rīga'},
      { id: 2,  name: 'Kuldīga'},
      { id: 3,  name: 'Valmiera'},
    ]
  }),
  created() {
    this.$store.dispatch("setDataLoading", true)

        // Get query params from URL
        this.queryParams = this.$route.query

        // If there are no URL params set default params
        if(Object.keys(this.queryParams).length === 0) {
            this.$router.replace({
                query: {
                    filter: 'all',
                    selectedBranch: '',
                    page: Search.getCurrentPage(),
                    q: this.search
                }
            })
        }

        // Update component data from query prams
        this.setFiltersFromParams()

        this.searchInventoryItems()
  },
  computed: {
    ...mapGetters({
      inventoryItems: 'inventoryItems',
      formsForDisplay: 'formsForDisplay',
      editableItem: 'editableItem',
      search: 'search',
    })
  },
  watch: {
    search: function () {
      this.$store.dispatch('changeSearch', this.search)
      this.searchInventoryItems()
    },
  },
  methods: {
    getItems() {
          this.$router.replace({
            query: {
                filter: this.filter.selectedFilter,
                selectedBranch: this.filter.selectedMainBranch ? this.filter.selectedMainBranch.id : '',
                page: 1,
                q: this.queryParams.q
            }
        })

        this.queryParams = this.$route.query
        this.searchInventoryItems()
    },

    setFiltersFromParams(){
        this.filter.selectedFilter = this.queryParams.filter ? this.queryParams.filter : "all"

        const selectedMainBranch = this.mainCustomerBranches.find(branch => {
            return branch.id == this.queryParams.selectedBranch
        })
        this.filter.selectedMainBranch = selectedMainBranch ? selectedMainBranch : '';
    },

    searchInventoryItems: debounce(function () {
      this.$store.dispatch('getInventoryItems', this.queryParams)
    }, 500),
    onPageChange(page) {
      this.searchInventoryItems()
    },

    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
    createInventoryItem() {
      this.$router.push({ path: '/stock/inventory_items/create' })
    },
    viewInventoryItem(itemUUID){
      this.$router.push({ path: '/stock/inventory_items/' + itemUUID })
    },
  }
}
</script>

<style>

</style>